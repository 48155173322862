import { Button, CircularProgress, Stack } from "@mui/material";

type TActionButtonns = {
  clearBtn?: string;
  submitBtn?: string;
  isLoading?: boolean;
  disabled?: boolean;
  onClear?: (reset) => void;
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
};
const ActionButtonns = (props: TActionButtonns) => {
  const {
    submitBtn = "Registrar",
    clearBtn = "Limpiar",
    isLoading,
    onClear,
    disabled,
    color = "primary",
  } = props;

  const showClearButton = typeof onClear === "function";

  return (
    <Stack direction={"row"} spacing={2}>
      {showClearButton && (
        <Button
          onClick={onClear}
          color="error"
          variant="contained"
          type="reset"
        >
          {clearBtn}
        </Button>
      )}

      <Button
        variant="contained"
        type="submit"
        disabled={isLoading || disabled}
        color={color}
      >
        {isLoading ? <CircularProgress size={24} /> : submitBtn}
      </Button>
    </Stack>
  );
};

export default ActionButtonns;
