import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import SelectSearch from "components/SelectSearch";
import ActionButtonns from "components/presentationals/ActionButtonns/ActionButtonns";
import { TextField } from "components/presentationals/Fields";
import SelectForm from "components/presentationals/Fields/TextFieldSelect";
import { Estatus } from "constants/estatus";
import { GRADO_INSTRUCCION } from "constants/grado_instruccion";
import { horasOpt } from "constants/horas";
import { EstadoCivil, NATIONALITY, RULES, prefijo } from "constants/index";
import { MODALIDAD } from "constants/modalidad";
import { TIPO_PERSONA } from "constants/tipo_persona";
import { ZonaEducativa } from "constants/zonaEducativa";
import dayjs from "dayjs";
import { useFormHook } from "hooks/useFormHook";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { IPROPS } from "interfaces";
import { SetStateAction, useEffect, useState } from "react";
import { SubmitHandler, useFieldArray } from "react-hook-form";
import { clearErrorsServer } from "reducers/appSlice";
import { ComunityService } from "services/EMP/comunity";
import { EstatesService } from "services/EMP/estate";
import { MunicipalitiesService } from "services/EMP/municipalities";
import { ParishesService } from "services/EMP/parishes";
import {
  getCharges,
  getCodUnico,
  getDependencies,
  getGrades,
  getMaterias,
} from "services/payroll";
import { RifCompanyService } from "services/rifCompany";
import { DEFAULT_VALUES, handleRegister, onClear } from "./utils";
import { getDataDirty } from "utils/helpers";
import { StringDecoder } from "string_decoder";
const DatosTrabajador = ({ initialValues, handleBack }: any) => {
  const [estates, setEstates] = useState([]);
  const [idEstates, setIdEstates] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [comunity, setComunity] = useState([]);
  const [Horas, setHoras] = useState<Array<any>>([]);
  const [cargos, setCargos] = useState([] as any);
  const [codigo, setCodigo] = useState([] as any);
  const [grados, setGrados] = useState([]);
  const [codUnico, setCodUnico] = useState({ count: 0, data: [], skip: 1 });
  const [parishes, setParishes] = useState([]);
  //const [materias, setMaterias] = useState({ count: 0, data: [], skip: 1 });
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(({ authReducer }) => authReducer);
  const iValues = {
    ...initialValues,
    ...initialValues?.dataIngreso,
    ...initialValues?.dataNomina,
  };

  const getEstates = async () => {
    try {
      const data = await EstatesService();
      setEstates(data.data.data as SetStateAction<never[]>);
    } catch (error) {
      console.log(error);
    }
  };

  const getGradosAll = async () => {
    try {
      const data = await getGrades();
      setGrados(data.data.data as SetStateAction<never[]>);
    } catch (error) {
      console.log(error);
    }
  };
  // const getChargesAll = async () => {
  //   try {
  //     /*  if (cargos.skip >= cargos.count) return; */
  //     const response: any = await getCharges({
  //       params: { take: 30, page: cargos.skip },
  //       authRequire: false,
  //     });

  //     setCargos((prevState: any) => {
  //       return {
  //         ...prevState,
  //         data: [...prevState.data, ...response.data.data],
  //         count: response.data.count,
  //         skip: prevState.skip + 1,
  //       };
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getDependeciesAll = async () => {
  //   try {
  //     const response: any = await getDependencies({
  //       params: {
  //         take: 30,
  //         page: codigo.skip,
  //       },
  //       authRequire: false,
  //     });

  //     setCodigo((prevState: any) => {
  //       return {
  //         ...prevState,
  //         data: [...prevState.data, ...response.data.data],
  //         count: response.data.count,
  //         skip: prevState.skip + 1,
  //       };
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getMunicipios = async (idEstate) => {
    try {
      const data = await MunicipalitiesService({
        params: { cod_entidad_ine: idEstate, limit: 30 },
      });
      setMunicipalities(data.data.data as SetStateAction<never[]>);
    } catch (error) {
      console.log(error);
    }
  };

  const getParroquias = async (idMunicipality) => {
    try {
      const data = await ParishesService({
        params: {
          cod_entidad_ine: idEstates,
          cod_municipio_ine: idMunicipality,
          limit: 50,
        },
      });
      setParishes(data.data.data as SetStateAction<never[]>);
    } catch (error) {
      console.log(error);
    }
  };

  const getCodUnique = async (cod_parroquia) => {
    try {
      const response: any = await getCodUnico({
        params: { take: 30, page: codUnico.skip, cod_parroquia },
        authRequire: false,
      });

      setCodUnico((prevState: any) => {
        return {
          ...prevState,
          data: [...prevState.data, ...response.data.data],
          count: response.data.count,
          skip: prevState.skip + 1,
        };
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getComunityAll = async (params) => {
    try {
      const data = await ComunityService({
        params,
      });
      setComunity(data.data.data as SetStateAction<never[]>);
    } catch (error) {
      console.log(error);
    }
  };

  // const getMateriasAll = async (params) => {
  //   try {
  //     const response = await getMaterias({
  //       params: { take: 30, page: 1, ...params },
  //     });

  //     setMaterias((prevState: any) => {
  //       return {
  //         ...prevState,
  //         data: [...prevState.data, ...response.data.data],
  //         count: response.data.count,
  //         skip: prevState.skip + 1,
  //       };
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getRifs = async (data, index) => {
    try {
      const nameInput = `antecedentes.${index}.ente_empresa`;
      const response = await RifCompanyService({ params: { rif_id: data } });
      setValue(nameInput, response.data.data[0].ente_empresa);
    } catch (error) {
      console.log(error);
    }
  };

  const decodedArrayAntecedents = (antecedentes) => {
    if (antecedentes.length > 0) {
      return antecedentes.map((antecendente, index) => {
        const result = {
          ...antecedentes[index],
          ente_empresa: antecendente?.rif?.ente_empresa,
          company_prefijo: String(antecendente?.tlf_company ?? "")
            .substring(0, 4)
            .trim(),
          company_tlf: String(antecendente?.tlf_company ?? "").substring(5),
        };

        delete result.rif;
        delete result.rif_id;
        delete result.tlf_company;

        return result;
      });
    } else {
      return [];
    }
  };

  DEFAULT_VALUES.nombre = iValues?.nombre_apellido
    ? iValues?.nombre_apellido.split(" ")[0]
    : iValues?.nombre
    ? iValues?.nombre
    : "";
  DEFAULT_VALUES.apellidos = iValues?.nombre_apellido
    ? iValues?.nombre_apellido.split(" ")[1]
    : iValues?.apellidos
    ? iValues?.apellidos
    : "";
  DEFAULT_VALUES.nacionalidad = iValues?.origen
    ? iValues?.origen.toUpperCase()
    : iValues?.nacionalidad
    ? iValues?.nacionalidad
    : "";
  DEFAULT_VALUES.ci = String(
    iValues?.cedula ? iValues?.cedula : iValues?.ci ? iValues?.ci : ""
  );
  DEFAULT_VALUES.f_nacimiento = String(
    iValues?.fecha_nacimiento
      ? iValues?.fecha_nacimiento
      : iValues?.f_nacimiento
      ? dayjs(iValues?.f_nacimiento).format("DD-MM-YYYY")
      : ""
  );
  DEFAULT_VALUES.f_ingreso = String(
    iValues?.fecha_ingreso
      ? iValues?.fecha_ingreso
      : iValues?.f_ingreso
      ? dayjs(iValues?.f_ingreso).format("DD-MM-YYYY")
      : ""
  );

  DEFAULT_VALUES.ccargo_id = iValues?.codigo_cargo
    ? iValues?.codigo_cargo
    : iValues?.cargo
    ? iValues?.cargo.id
    : "";

  // DEFAULT_VALUES.cod_banco = String(
  //   iValues?.codigo_banco
  //     ? iValues?.codigo_banco
  //     : iValues?.cod_banco
  //       ? iValues?.cod_banco
  //       : ""
  // );
  DEFAULT_VALUES.nro_cuenta = String(
    iValues?.cuenta_banco
      ? iValues?.cuenta_banco
      : iValues?.nro_cuenta
      ? iValues?.nro_cuenta
      : ""
  );
  DEFAULT_VALUES.sexo = String(
    iValues?.sexo
      ? iValues?.sexo
      : iValues?.sexo
      ? iValues?.sexo === "M"
        ? "MASCULINO"
        : "FEMENINO"
      : ""
  );

  DEFAULT_VALUES.cdependencia_id = String(
    iValues?.codigo_dependencia
      ? iValues?.codigo_dependencia
      : iValues?.cdependencia_id
      ? iValues?.cdependencia_id.cdependencia
      : ""
  );

  DEFAULT_VALUES.tpersona = String(
    iValues?.clasificacion
      ? iValues?.clasificacion[0].toUpperCase()
      : iValues?.tpersona
      ? iValues?.tpersona
      : ""
  );
  DEFAULT_VALUES.estado_civil = String(
    iValues?.estado_civil
      ? iValues?.estado_civil[0].toUpperCase()
      : iValues?.estado_civil
      ? iValues?.estado_civil
      : ""
  );

  DEFAULT_VALUES.id_estado = iValues?.estado_id ?? "";

  DEFAULT_VALUES.id_municipio = iValues?.municipio_id ?? "";

  DEFAULT_VALUES.id_parroquia = iValues?.parroquia_id ?? "";

  DEFAULT_VALUES.comunidades_id = iValues?.comunidades_id ?? "";
  DEFAULT_VALUES.antecedentes = decodedArrayAntecedents(
    iValues?.antecedentes ?? []
  );

  Object.keys(DEFAULT_VALUES).forEach((key) => {
    if (iValues?.[key]) {
      DEFAULT_VALUES[key] = iValues?.[key] ?? "";
    }
  });

  const {
    getValues,
    errors,
    control,
    reset,
    setValue,
    watch,
    clearErrors,
    handleSubmit,
  } = useFormHook(DEFAULT_VALUES);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "antecedentes",
  } as any);

  const watchFieldArray = watch("antecedentes");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const tlfFormArray = (array) => {
    if (array.length > 0) {
      array.map((element) => {
        element.tlf_company = `${element.company_prefijo}${element.company_tlf}`;

        delete element.company_prefijo;
        delete element.company_tlf;
        return element;
      });
    } else {
      return;
    }

    return array;
  };

  const onSubmit: SubmitHandler<IPROPS> = (data) => {
    let dirtyData = data;
    if (iValues?.id) {
      dirtyData = getDataDirty(control._defaultValues as any, data as any);
    }

    if (dirtyData.tpersona !== "D") {
      delete dirtyData.grados_id;
      delete dirtyData.modalidad;
      delete dirtyData.materias_id;
      delete dirtyData.n_instrucion;
    }

    if (dirtyData.prefijo_telefono || dirtyData.tlf) {
      dirtyData.tlf = `${data.prefijo_telefono}${data.tlf}`;
    }

    if (dirtyData.ccargo_id) {
      dirtyData.ccargo_id = Number(dirtyData.ccargo_id);
    }
    if (dirtyData.grados_id) {
      dirtyData.grados_id = Number(dirtyData.grados_id);
    }
    if (dirtyData.materias_id) {
      dirtyData.materias_id = Number(dirtyData.materias_id);
    }
    if (dirtyData.cod_unico_id) {
      dirtyData.cod_unico_id = Number(dirtyData.cod_unico_id);
    }

    if (dirtyData.antecedentes) {
      dirtyData.antecedentes = tlfFormArray(dirtyData.antecedentes);
    }

    handleRegister({
      data: dirtyData,
      clearErrors,
      reset,
      dispatch,
      handleBack,
      updateId: iValues?.id || null,
    });
    dispatch(clearErrorsServer());
  };

  useEffect(() => {
    if (DEFAULT_VALUES.id_estado) {
      getMunicipios(DEFAULT_VALUES.id_estado);
    }
  }, [DEFAULT_VALUES.id_estado]);

  useEffect(() => {
    if (DEFAULT_VALUES.id_municipio) {
      getParroquias(DEFAULT_VALUES.id_municipio);
    }
  }, [DEFAULT_VALUES.id_municipio]);

  useEffect(() => {
    if (DEFAULT_VALUES.id_parroquia) {
      getComunityAll({
        cod_parroquia_ine: DEFAULT_VALUES.id_parroquia,
        cod_entidad_ine: DEFAULT_VALUES.id_estado,
        cod_municipio_ine: getValues("id_municipio"),
      });
    }
  }, [DEFAULT_VALUES.id_parroquia]);

  useEffect(() => {
    getEstates();
    /*   initHoras(); */
    // getChargesAll();

    getGradosAll();
    // getDependeciesAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getValues("tpersona")) {
      setHoras(
        horasOpt.filter(
          (hora) => hora.tpersona === (getValues("tpersona") as any)
        ) as any
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      ...DEFAULT_VALUES,
      estatus: String(iValues?.estatus ?? "").trim(),
      tlf: String(iValues?.tlf ?? "").substring(4),
      prefijo_telefono: String(iValues.tlf ?? "").substring(0, 4),
      ccargo_id: iValues?.codigo_cargo
        ? iValues?.codigo_cargo
        : iValues?.cargo
        ? iValues?.cargo.id
        : "",
      materias_id: String(
        iValues?.materias_id?.id
          ? iValues?.materias_id?.id
          : iValues?.materias_id ?? ""
      ),
      grados_id: String(iValues?.grados_id ?? ""),
      f_nacimiento: String(
        iValues?.fecha_nacimiento
          ? iValues?.fecha_nacimiento
          : iValues?.f_nacimiento
          ? dayjs(iValues?.f_nacimiento).format("DD-MM-YYYY")
          : ""
      ),
      cod_unico_id: iValues?.codigo_unico?.id ?? "",
      antecedentes: decodedArrayAntecedents(iValues?.antecedentes ?? []),
    } as any);
  }, [
    iValues?.antecedentes,
    iValues?.cargo,
    iValues?.codigo_cargo,
    iValues?.codigo_unico?.id,
    iValues?.f_nacimiento,
    iValues?.fecha_nacimiento,
    iValues?.grados_id,
    iValues?.materias_id?.id,
    iValues.tlf,
    reset,
  ]);

  useEffect(() => {
    if (iValues?.codigo_cargo ? true : false) {
      setValue("ccargo_id", cargos[0]?.id);
    }

    if (iValues?.codigo_dependencia ? true : false) {
      setValue("cdependencia_id", codigo[0]?.id);
    }
    console.log("codigo", codigo);
  }, [cargos, codigo]);

  return (
    <>
      <Box
        sx={{
          marginTop: 6,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Stack direction="column" spacing={1}>
          <Typography variant="h6" color="#000" fontWeight="bold">
            Ingreso del trabajador
          </Typography>
          <Typography align="center" color="error" sx={{ fontStyle: "italic" }}>
            Complete el siguiente formulario, para generar el ingreso o también
            puede cancelar y regresar a la consulta...
          </Typography>
        </Stack>
        <Button
          variant="outlined"
          startIcon={<DeleteIcon />}
          color="error"
          onClick={handleBack}
        >
          Cancelar
        </Button>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ mt: 6 }} spacing={5} alignItems={"center"}>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={12} md={6}>
              <SelectForm
                disabled
                readOnly
                name="nacionalidad"
                variant="filled"
                control={control}
                options={NATIONALITY}
                label="nacionalidad"
                defaultValue="V"
                rules={RULES.requerido}
                error={Boolean(errors.nacionalidad)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                readOnly
                name="ci"
                type="text"
                label="Número de Cédula"
                variant="filled"
                control={control}
                rules={RULES.cedula}
                error={Boolean(errors.ci)}
                maxLength={10}
                errmsg={errors.ci}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                readOnly
                name="nombre"
                type="text"
                label="Primer Nombre"
                variant="filled"
                control={control}
                rules={RULES.nombres}
                error={Boolean(errors.nombre)}
                errmsg={errors.nombre}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                readOnly
                name="apellidos"
                type="text"
                label="Primer Apellido"
                variant="filled"
                control={control}
                rules={RULES.nombres}
                error={Boolean(errors.nombre)}
                errmsg={errors.nombre}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                readOnly
                disabled
                name="f_nacimiento"
                type="date"
                label="Fecha de Nacimiento"
                variant="filled"
                control={control}
                labelProps={{ shrink: true }}
                rules={RULES.requerido}
                error={Boolean(errors.f_nacimiento)}
                errmsg={errors.f_nacimiento}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                readOnly
                disabled
                name="sexo"
                label="Sexo"
                variant="filled"
                control={control}
                labelProps={{ shrink: true }}
                rules={RULES.requerido}
                error={Boolean(errors.sexo)}
                errmsg={errors.sexo}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SelectForm
                readOnly={iValues?.clasificacion ? true : false}
                disabled={iValues?.clasificacion ? true : false}
                name="tpersona"
                itemId="value"
                itemLabel="label"
                variant="filled"
                control={control}
                options={TIPO_PERSONA}
                label="Tipo de persona"
                handleChange={(e) => {
                  setHoras(
                    horasOpt.filter((hora) => hora.tpersona === e.target.value)
                  );
                }}
                rules={RULES.requerido}
                error={Boolean(errors.tpersona)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SelectForm
                name="horas"
                control={control}
                options={Horas}
                label="Horas"
                variant="filled"
                defaultValue=""
                rules={RULES.requerido}
                itemId="hr"
                itemLabel="hr"
                error={Boolean(errors.horas)}
              />
            </Grid>
            {(getValues("tpersona") as any) === "D" && (
              <Grid item xs={12} md={6}>
                <SelectForm
                  name="modalidad"
                  control={control}
                  options={MODALIDAD}
                  label="Turno"
                  variant="filled"
                  defaultValue=""
                  rules={RULES.requerido}
                  itemId="value"
                  itemLabel="label"
                  error={Boolean(errors.horas)}
                />
              </Grid>
            )}

            {(getValues("tpersona") as any) === "D" && (
              <Grid item xs={12} md={6}>
                <SelectForm
                  name="n_instrucion"
                  control={control}
                  options={GRADO_INSTRUCCION}
                  label="Grado de instrucción"
                  variant="filled"
                  defaultValue=""
                  rules={RULES.requerido}
                  itemId="value"
                  itemLabel="label"
                  error={Boolean(errors.horas)}
                />
              </Grid>
            )}
            {(getValues("tpersona") as any) === "D" && (
              <Grid item xs={12} md={6}>
                <SelectForm
                  name="grados_id"
                  control={control}
                  options={grados}
                  label="Grados"
                  variant="filled"
                  rules={RULES.requerido}
                  itemId="id_grado"
                  itemLabel="nombre_grd"
                  error={Boolean(errors.horas)}
                />
              </Grid>
            )}

            {(getValues("tpersona") as any) === "D" && (
              <Grid item xs={12} sm={12}>
                <SelectSearch
                  async={true}
                  label="Materias"
                  defaultValueText={
                    iValues?.materias_id?.nombre
                      ? iValues?.materias_id?.nombre
                      : iValues?.materias?.nombre
                      ? iValues?.materias?.nombre
                      : ""
                  }
                  name="materias_id"
                  service={getMaterias}
                  params={{ take: 100, page: 1 }}
                  nameSearch="nombre"
                  control={control}
                  rules={RULES.requerido}
                  itemLabel="nombre"
                  itemValue="id"
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <TextField
                readOnly={iValues?.fecha_ingreso ? true : false}
                disabled={iValues?.fecha_ingreso ? true : false}
                name="f_ingreso"
                type="date"
                label="Fecha de Ingreso"
                variant="filled"
                control={control}
                labelProps={{ shrink: true }}
                rules={RULES.requerido}
                error={Boolean(errors.f_ingreso)}
                errmsg={errors.f_ingreso}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectForm
                name="estado_civil"
                variant="filled"
                control={control}
                options={EstadoCivil}
                label="Estado Civil"
                defaultValue=""
                rules={RULES.requerido}
                error={Boolean(errors.estado_civil)}
                readOnly={iValues?.codigo_cargo ? true : false}
                disabled={iValues?.codigo_cargo ? true : false}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <SelectSearch
                async={true}
                label="Código Dependencia"
                itemLabel2="cdependencia"
                setterOptions={setCodigo}
                defaultValueText={
                  String(
                    initialValues.dataNomina?.dependencia
                      ? initialValues.dataNomina?.dependencia
                      : initialValues.dataIngreso?.dependencia.ddependencia
                      ? initialValues.dataIngreso?.dependencia.ddependencia
                      : ""
                  ) as any
                }
                name="cdependencia_id"
                control={control}
                service={getDependencies}
                nameSearch="ddependencia"
                itemValue="id"
                itemValue2="cdependencia"
                rules={RULES.requerido}
                itemLabel="ddependencia"
                params={{ take: 100, page: 1 }}
                readOnly={iValues?.codigo_dependencia ? true : false}
                disabled={iValues?.codigo_dependencia ? true : false}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              {/* <SelectForm
                name="ccargo_id"
                control={control}
                itemId="id"
                nextData={getChargesAll}
                // defaultValue={
                //   iValues?.codigo_cargo
                //     ? iValues?.codigo_cargo
                //     : iValues?.ccargo_id
                //     ? iValues?.ccargo_id.ccargo
                //     : ""
                // }
                defaultData={
                  (iValues?.codigo_cargo || iValues?.ccargo_id) && {
                    ccargo: iValues?.codigo_cargo
                      ? iValues?.codigo_cargo
                      : iValues?.ccargo_id
                      ? iValues?.ccargo_id.id
                      : "",
                    dcargo: iValues?.cargo
                      ? iValues?.cargo
                      : iValues?.ccargo_id
                      ? iValues?.ccargo_id?.dcargo
                      : "",
                  }
                }
                itemLabel="ccargo"
                itemLabel2="dcargo"
                options={cargos.data}
                label="Código de Cargo"
                variant="filled"
                rules={RULES.numberCargo}
                error={Boolean(errors.ccargo_id)}
              /> */}
              <SelectSearch
                async={true}
                label="Código de Cargo"
                setterOptions={setCargos as any}
                itemLabel2="ccargo"
                defaultValueText={
                  String(
                    initialValues.dataNomina?.cargo
                      ? initialValues.dataNomina?.cargo
                      : initialValues.dataIngreso?.cargo.dcargo
                      ? initialValues.dataIngreso?.cargo.dcargo
                      : ""
                  ) as any
                }
                name="ccargo_id"
                control={control}
                service={getCharges}
                nameSearch="dcargo"
                itemValue="id"
                itemValue2="ccargo"
                rules={RULES.requerido}
                itemLabel="dcargo"
                params={{ take: 100, page: 1 }}
                readOnly={iValues?.codigo_cargo ? true : false}
                disabled={iValues?.codigo_cargo ? true : false}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                readOnly={iValues?.cuenta_banco ? true : false}
                disabled={iValues?.cuenta_banco ? true : false}
                name="nro_cuenta"
                type="text"
                label="Número Cuenta Bancaria"
                variant="filled"
                control={control}
                rules={RULES.number}
                maxLength={20}
                error={Boolean(errors.nro_cuenta)}
                errmsg={errors.nro_cuenta}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={4} display="flex">
                  <SelectForm
                    name="prefijo_telefono"
                    variant="filled"
                    control={control}
                    options={prefijo}
                    label="Prefijo Tlf"
                    defaultValue="1"
                    rules={RULES.requerido}
                    itemId="value"
                    itemLabel="value"
                    error={Boolean(errors.prefijoTelefono)}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name="tlf"
                    type="text"
                    label="Número Teléfono"
                    variant="filled"
                    control={control}
                    rules={RULES.number}
                    maxLength={7}
                    error={Boolean(errors.tlf)}
                    errmsg={errors.tlf}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectForm
                name="estatus"
                control={control}
                itemId="value"
                itemLabel="label"
                options={Estatus}
                label="Estatus"
                variant="filled"
                rules={RULES.requerido}
                error={Boolean(errors.estado)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <SelectForm
                name="zona_educativa"
                variant="filled"
                control={control}
                options={ZonaEducativa}
                label="Centro de calidad educativa"
                itemId="value"
                itemLabel="value"
                rules={RULES.requerido}
                error={Boolean(errors.zona_educativa)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SelectForm
                name="id_estado"
                itemId="cod_entidad_ine"
                itemLabel="entidad_ine"
                handleChange={(e) => {
                  getMunicipios(e.target.value);
                  setIdEstates(e.target.value);
                }}
                variant="filled"
                control={control}
                options={estates}
                rules={RULES.requerido}
                label="Estados"
                error={Boolean(errors.nacionalidad)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectForm
                name="id_municipio"
                variant="filled"
                itemId="cod_municipio_ine"
                itemLabel="municipio_ine"
                control={control}
                handleChange={(e) => {
                  getParroquias(e.target.value);
                }}
                options={municipalities}
                rules={RULES.requerido}
                label="Municipio"
                error={Boolean(errors.nacionalidad)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectForm
                name="id_parroquia"
                variant="filled"
                control={control}
                handleChange={(e) => {
                  getComunityAll({
                    cod_parroquia_ine: e.target.value,
                    cod_entidad_ine: idEstates,
                    cod_municipio_ine: getValues("id_municipio"),
                  });
                }}
                options={parishes}
                label="Parroquia"
                itemId="cod_parroquia_ine"
                rules={RULES.requerido}
                itemLabel="parroquia_ine"
                error={Boolean(errors.nacionalidad)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectSearch
                async={true}
                label="Código único"
                defaultValueText={
                  String(iValues?.codigo_unico?.codigo_unico ?? "") as any
                }
                name="cod_unico_id"
                control={control}
                service={getCodUnico}
                nameSearch="codigo_unico"
                itemValue="id"
                rules={RULES.requerido}
                itemLabel="codigo_unico"
                params={{ take: 100, page: 1 }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SelectForm
                name="comunidades_id"
                variant="filled"
                control={control}
                options={comunity}
                label="Comunidad"
                itemId="id_comunidad_ine"
                rules={RULES.requerido}
                itemLabel="nombre_comunidad"
                error={Boolean(errors.comunidades_id)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" color="#000" fontWeight="bold">
                Antecedentes
              </Typography>
            </Grid>
            {controlledFields.map((field, index) => {
              return (
                <Grid item xs={12} sm={12} key={field.id}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={11}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name={`antecedentes.${index}.rif_id`}
                            handleChange={(e) => {
                              if (e.target.value.length === 10) {
                                getRifs(e.target.value, index);
                              }
                            }}
                            type="text"
                            label="RIF"
                            variant="filled"
                            maxLength={10}
                            control={control}
                            rules={RULES.requerido}
                            error={Boolean(
                              errors.antecedentes?.[index]?.rif_id
                            )}
                            errmsg={errors.antecedentes?.[index]?.rif_id}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <TextField
                            name={`antecedentes.${index}.ente_empresa`}
                            type="text"
                            label="Nombre de la institución"
                            labelProps={{ shrink: true }}
                            variant="filled"
                            disabled
                            control={control}
                            rules={RULES.nombres}
                            error={Boolean(
                              errors.antecedentes?.[index]?.ente_empresa
                            )}
                            errmsg={errors.antecedentes?.[index]?.ente_empresa}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            name={`antecedentes.${index}.fingreso`}
                            type="date"
                            label="Desde"
                            variant="filled"
                            control={control}
                            labelProps={{ shrink: true }}
                            rules={RULES.requerido}
                            error={Boolean(
                              errors.antecedentes?.[index]?.fingreso
                            )}
                            errmsg={errors.antecedentes?.[index]?.fingreso}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            name={`antecedentes.${index}.fegreso`}
                            type="date"
                            label="Hasta"
                            variant="filled"
                            control={control}
                            labelProps={{ shrink: true }}
                            rules={RULES.requerido}
                            error={Boolean(
                              errors.antecedentes?.[index]?.fegreso
                            )}
                            errmsg={errors.antecedentes?.[index]?.fegreso}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={4} display="flex">
                              <SelectForm
                                name={`antecedentes.${index}.company_prefijo`}
                                variant="filled"
                                control={control}
                                options={prefijo}
                                label="Prefijo Tlf"
                                defaultValue={
                                  iValues?.antecedentes?.[index]
                                    ? iValues?.antecedentes?.[
                                        index
                                      ]?.tlf_company.substring(0, 4)
                                    : ""
                                }
                                rules={RULES.requerido}
                                itemId="value"
                                itemLabel="value"
                                error={Boolean(
                                  errors.antecedentes?.[index]?.company_prefijo
                                )}
                                errmsg={
                                  errors.antecedentes?.[index]?.company_prefijo
                                }
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                name={`antecedentes.${index}.company_tlf`}
                                type="text"
                                label="Número Teléfono"
                                variant="filled"
                                control={control}
                                rules={RULES.number}
                                maxLength={7}
                                error={Boolean(
                                  errors.antecedentes?.[index]?.company_tlf
                                )}
                                errmsg={
                                  errors.antecedentes?.[index]?.company_tlf
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

            <Button
              type="button"
              style={{ marginLeft: "15px", marginTop: "15px" }}
              variant="contained"
              onClick={() => {
                return append({
                  rif_id: "",
                  ente_empresa: "",
                  fegreso: "",
                  fingreso: "",
                  company_prefijo: "",
                  company_tlf: "",
                });
              }}
            >
              Agregar antecedente
            </Button>
            <Grid item xs={12}>
              <TextField
                name="dir_habitacion"
                type="text"
                label="Dirección Habitación"
                multiline
                variant="filled"
                control={control}
                rules={RULES.requerido}
                error={Boolean(errors.dir_habitacion)}
                errmsg={errors.dir_habitacion}
              />
            </Grid>
          </Grid>

          {/** Botones de acciones */}

          <ActionButtonns
            submitBtn={iValues?.dataIngreso ? "Guardar" : "Registrar"}
            isLoading={isLoading}
            onClear={() => (iValues?.dataIngreso ? undefined : onClear(reset))}
          />
        </Stack>
      </form>
    </>
  );
};

export default DatosTrabajador;
