import { localToken, recoverToken } from "constants/index";
import { RequestOptionsType } from "interfaces/api";
import jwt_decode from "jwt-decode";
import { removeItemLocal, searchItemLocal } from "utils/helpers";
import API from "./api";

const getHeaders = (authRequired: boolean) => {
  if (authRequired) {
    const authotization = searchItemLocal(localToken);
    const authotizationRecover = searchItemLocal(recoverToken);

    const tokenDecoded: any = authotization ? jwt_decode(authotization) : null;
    const tokenRecoverDecoded: any = authotizationRecover
      ? jwt_decode(authotizationRecover)
      : null;

    if (tokenRecoverDecoded?.exp * 1000 < Date.now()) {
      return removeItemLocal(recoverToken);
    } else if (tokenDecoded?.exp * 1000 < Date.now()) {
      return removeItemLocal(localToken);
    }

    return authotization
      ? { Authorization: `Bearer ${authotization}` }
      : authotizationRecover
      ? { Authorization: `Bearer ${authotizationRecover}` }
      : {};
  }

  return {};
};

export type AxiosResult<T> = {
  responseType?: any;
  data?: T;
  error?: any;
  code?: string;
  status?: number | string;
};

export const request = async <T = any>(
  url: string,
  options: RequestOptionsType = {}
): Promise<AxiosResult<T>> => {
  const {
    method = "get",
    data = {},
    authRequire = false,
    params,
    headers = {},
  } = options;

  return await API.request<T>({
    url,
    params,
    method,
    data,
    headers: { ...getHeaders(authRequire), ...headers },
  });
};
