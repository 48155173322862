import HomeIcon from "@mui/icons-material/Home";
import { Breadcrumbs, Link } from "@mui/material";
import { NavLink } from "react-router-dom";

const Breadcrumb = ({
  breadcrumbs,
}: {
  breadcrumbs: { path: string; label: string }[];
}) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
      <Link
        underline="hover"
        sx={{ display: "flex", alignItems: "center" }}
        color="inherit"
        href="/"
      >
        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        Inicio
      </Link>
      {breadcrumbs.map(({ label, path }, index) => (
        <NavLink
          key={`${index}-${path}`}
          to={path}
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }
        >
          {label}
        </NavLink>
      ))}
    </Breadcrumbs>
  );
};
export default Breadcrumb;
