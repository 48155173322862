// @PREXIs
export const PREFIX_DASHBOARD = "/ingreso";
export const PREFIX_ADMIN = "/administracion";

// @NAVIGATION
export const NAVIGATION = {
  HOME: PREFIX_DASHBOARD,
  LOGIN: `/iniciar-sesion`,
  // CONSTRUCTION
  CONSTRUCTION: "/construccion",
};
export default NAVIGATION;
