import { Grid, Stack } from "@mui/material";
import { TextField } from "components/presentationals/Fields";
import SelectForm from "components/presentationals/Fields/TextFieldSelect";
import { NATIONALITY, RULES } from "constants/index";
import { useFormHook } from "hooks/useFormHook";
import { IPROPS } from "interfaces";
import { SubmitHandler } from "react-hook-form";
import ActionButtonns from "../ActionButtonns/ActionButtonns";

type TInputs = {
  nacionalidad: string;
  ci: string;
};

type TSearchFormCedula = {
  disabled?: boolean;
  defaultValues: TInputs;
  onSubmit: SubmitHandler<IPROPS>;
  onBlurCedula?: Function | undefined;
  nextColor?:
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
};

const SearchFormCedula = (props: TSearchFormCedula) => {
  const { defaultValues, onSubmit, onBlurCedula, disabled } = props;
  const { errors, control, setValue, getValues, handleSubmit } =
    useFormHook(defaultValues);

  const handleBlurCedula = (e) => {
    if (typeof onBlurCedula === "function") {
      onBlurCedula({ e, getValues, setValue });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={5} alignItems={"center"}>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12} md={5}>
            <SelectForm
              name="nacionalidad"
              variant="filled"
              control={control}
              options={NATIONALITY}
              label="Nacionalidad"
              defaultValue="V"
              rules={RULES.requerido}
              error={Boolean(errors.nacionalidad)}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              name="ci"
              type="text"
              label="Número de Cédula"
              variant="filled"
              control={control}
              rules={RULES.cedula}
              error={Boolean(errors.ci)}
              handleBlur={handleBlurCedula}
              maxLength={10}
              errmsg={errors.ci}
            />
          </Grid>
        </Grid>
        {/** Botones de acciones */}
        <ActionButtonns
          submitBtn="Buscar"
          isLoading={false}
          disabled={disabled}
        />
      </Stack>
    </form>
  );
};

export default SearchFormCedula;
