export const TIPO_PERSONA = [
    {
        value: "A",
        label: "Administrativo"
    },
    {
        value: "O",
        label: "Obrero"
    },
    {
        value: "D",
        label: "Docente"
    }
]