import { request } from "config/request";
import { API_CUENTA, API_SAIME } from "constants/index";
import { iRequest } from "interfaces/api";
/**
 * Sends a request to '/constancia' with the given payload and parameters.
 *
 * @param props - The request properties.
 * @returns A promise that resolves to the response of the request.
 */
export const SaimeService = (props: iRequest) => {
  const { authRequire, payload } = props;

  const { nacionalidad, ci } = payload as { nacionalidad: string; ci: string };

  return request(`${API_SAIME}/saime/${nacionalidad}/${ci}/`, {
    method: "get",
    authRequire,
  });
};

export const RegisterUser = (props: iRequest) => {
  const { authRequire, payload } = props;
  const { horas, sexo, ...restData } = payload as Record<string, string>;

  if (sexo.toLocaleLowerCase().startsWith("m")) {
    restData.sexo = "M";
  } else {
    restData.sexo = "F";
  }

  return request(`${API_CUENTA}/payroll`, {
    method: "post",
    data: { ...restData, horas: horas },
    authRequire,
  });
};

export const UpdateUser = (props: iRequest) => {
  const { authRequire, payload } = props;
  const { horas, sexo, id, ...restData } = payload as Record<string, string>;
  if(sexo){
    if (sexo.toLocaleLowerCase().startsWith("m")) {
      restData.sexo = "M";
    } else {
      restData.sexo = "F";
    }
  }

 

  return request(`${API_CUENTA}/payroll/${id}`, {
    method: "put",
    data: { ...restData, horas: horas },
    authRequire,
  });
};
