const regex = {
  usuario: /^[A-Za-z1-90@.]+$/i,
  password: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
  nombres: /^[A-Za-záéúíóàèìòùÑñ ]+$/i,
  cedula: /^[0-9+]+/,
  email: /[A-Z0-9a-z._%+-]+@gmail\.com+/g,
  no_space: /^[\w\S]+$/g,
  no_special: /^[\w\s.:,"()áéúíóàèìòùÑñ-]+$/g,
  number: /^[0-9+]+/,
  numberCargo: /^[a-zA-Z0-9]+$/,
  horas: /^[0-9,]+$/,
};
const RULES = {
  requerido: {
    required: { value: true, message: 'Campo requerido' },

  },
  nombres: {
    required: { value: true, message: 'Campo requerido' },
    pattern: {
      value: regex.nombres,
      message: 'Solo se aceptan letras'
    }
  },

  username: {
    required: { value: true, message: 'Campo requerido' },
    pattern: {
      value: regex.usuario,
      message: 'No se aceptan caracteres especiales ni espacio'
    }
  },
  numberCargo: {
    required: { value: true, message: 'Campo requerido' },
    pattern: {
      value: regex.numberCargo,
      message: 'No se aceptan letras y números'
    }
  },
  horas: {
    required: { value: true, message: 'Campo requerido' },
    pattern: {
      value: regex.horas,
      message: 'Solo se aceptan numeros y una coma'
    }
  },
  no_required_no_special: {
    pattern: {
      value: regex.no_special,
      message: 'Solo se aceptan los siguientes caracteres: . : , " ( )'
    }
  },
  correo: {
    required: { value: true, message: 'Campo requerido' },
    pattern: {
      value: regex.email,
      message: 'Solo se acepta el correo institucional (@gmail.com)'
    }
  },

  cedula: {
    maxLength: { value: 10, message: 'Maximo 10 caracteres' },
    required: { value: true, message: 'Campo requerido' },
    pattern: { value: regex.cedula, message: 'Solo acepta numeros' }
  },
  contraseña: {
    minLength: { value: 8, message: 'Minimo 8 caracteres' },
    required: { value: true, message: 'Campo requerido' },
    pattern: { value: regex.password, message: 'La contraseña debe ser mínimo de 8 caracteres y debe contener mayúscula, minúsculas, números y símbolos' }
  },
  codigo: {
    maxLength: { value: 7, message: 'Maximo 7 caracteres alfanumericos' },
    required: { value: true, message: 'Campo requerido' }
  },
  no_space: {
    required: { value: true, message: 'Campo requerido' },
    pattern: {
      value: regex.no_space,
      message: 'No se aceptan espacios en blanco'
    }
  },
  no_special: {
    required: { value: true, message: 'Campo requerido' },
    pattern: {
      value: regex.no_special,
      message: 'Solo se aceptan los siguientes caracteres: . : , " ( )'
    }
  },
  number: {
    required: { value: true, message: 'Campo requerido' },
    pattern: {
      value: regex.number,
      message: 'Solo se aceptan numeros'
    }
  }
};

export { RULES };
