export const Estatus = [{
    value: 'J',
    label: 'JUBILADOS'
}, {
    value: 'E',
    label: 'EGRESADOS'
}
    , {
    value: 'S',
    label: 'SUSPENDIDOS'
},
{
    value: 'R',
    label: 'RENUNCIA'
},
{
    value: 'A',
    label: 'ACTIVO'
},
{
    value: 'I',
    label: 'INDETERMINADO'
},
{
    value: 'I',
    label: 'INGRESO'
}]

