import axios, { AxiosInstance } from "axios";
import NAVIGATION from "routes/navigation";
import { removeItemLocal } from "utils/helpers";
import { localToken } from "../constants";

const API: AxiosInstance = axios.create({
  baseURL: "",
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: any) => {
    if (
      [403, 401].includes(error?.response?.data?.status) ||
      [403, 401].includes(error?.response?.status)
    ) {
      removeItemLocal(localToken);
      setTimeout(() => {
        window.location.href = NAVIGATION.LOGIN;
      }, 1000);
    }
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    // Handle successful response
    return response;
  },
  (error) => {
    // Handle error response
    return Promise.reject(error);
  }
);

export default API;
