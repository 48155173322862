import { request } from "config/request";
import { iRequest } from "interfaces/api";

export const RifCompanyService = (props: iRequest) => {
    const { params } = props;
    return request(`https://sistad.mppe.gob.ve/api/public-rif`, {
        method: "get",
        params: params,
    });
};
