export const GRADO_INSTRUCCION = [
    {
        value: "1",
        label: "ESPECIALIZACIÓN"
    },
    {
        value: "2",
        label: "MAESTRÍA"
    },
    {

        value: "3",
        label: "DOCTORADO"
    },
    {
        value: "4",
        label: "OTRO"
    },
    {
        value: "5",
        label: "DIPLOMADO"
    },
    {
        value: "6",
        label: "PHD"
    }
]