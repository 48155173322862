import {
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { DISPLAY } from "config/enums";
import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import { MenuRoutes } from "routes";
import { myStylesList } from "static/styles-mui/stylesMenu";
import { iconList } from "../constants";
import "./ListMenu.scss";

const ListMenu: React.FC<ListMenuProps> = ({ movil }) => {
  const classes = myStylesList({ movil });

  return (
    <List component="nav" className={classes.nav}>
      {MenuRoutes &&
        MenuRoutes?.length &&
        MenuRoutes?.map(({ id, name, path, icon, display }: any) => {
          const Xicon = iconList[icon];
          return (
            DISPLAY.principal === display && (
              <NavLink
                key={id}
                to={path}
                end
                className={({ isActive }) =>
                  isActive ? `${classes.item} ${classes.active}` : classes.item
                }
              >
                <ListItem className="list_xs">
                  {Xicon ? (
                    <ListItemIcon>
                      <Tooltip arrow title={name} placement="right">
                        <Xicon />
                      </Tooltip>
                    </ListItemIcon>
                  ) : null}
                  <Typography fontSize={16} variant="subtitle2">
                    {name}
                  </Typography>
                </ListItem>
              </NavLink>
            )
          );
        })}
    </List>
  );
};

/**
 * params para componente ListMenu
 * @method type ListMenuProps
 * @param movil?: {boolean} Verificar si es boolean (true, false)
 */
export type ListMenuProps = { movil: boolean };
export default memo(ListMenu);
