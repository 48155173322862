import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Button, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAppDispatch } from "hooks/useRedux";
import * as React from "react";
import { useNavigate } from "react-router";
import { logout } from "reducers/authSlice";
import LogoutIcon from "@mui/icons-material/Logout";
import NAVIGATION from "routes/navigation";

const myStyles = makeStyles({
  AppBar: {
    boxShadow: "unset !important",
    borderBottom: "1px solid #e2e8f0",
  },
  menu: {
    padding: "10px !important",
  },
  name: {
    fontWeight: "normal",
    fontSize: "18px",
  },
  cargo: {
    fontWeight: "normal",
    fontSize: "14px",
  },
});

const BarApp: React.FC<{ handleOpen?: () => void }> = ({ handleOpen }) => {
  const dispatch = useAppDispatch();
  const classes = myStyles();
  const navigate = useNavigate();

  return (
    <>
      <AppBar position="static" color="transparent" className={classes.AppBar}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1em 2em",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleOpen}
          >
            <MenuIcon />
          </IconButton>
          <Box>
            <Button
              onClick={() => {
                dispatch(logout());
                navigate(NAVIGATION.LOGIN);
              }}
              variant="outlined"
              sx={{ borderColor: "#1976d2" }}
            >
              <Typography
                variant="h6"
                sx={{
                  m: 0,
                  color: "#1976d2",
                  textTransform: "math-auto",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <LogoutIcon /> Cerrar sesión
              </Typography>
            </Button>
          </Box>
        </Box>
      </AppBar>
    </>
  );
};
export default BarApp;
