import {
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { GridSearchIcon } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";

const SelectSearch = ({
  label,
  async = false,
  itemLabel = "label",
  itemLabel2 = "",
  itemValue = "value",
  itemValue2 = "",
  setterOptions = (options) => {},
  options = [] as any,
  defaultValueText = "",
  params = {},
  nameSearch = "",
  searchItem = "nombre",
  readOnly = false,
  disabled = false,
  service = (params) => {},
  name,
  rules,
  control,
}) => {
  const [selectedOption, setSelectedOption] = useState(options) as any;
  const [searchText, setSearchText] = useState("");
  const [asyncOptions, setAsyncOptions] = useState(options) as any;

  const handleSearch = async () => {
    try {
      // if (searchText === "") return;
      const response: any = await service({
        params: { ...params, [nameSearch]: searchText },
      });
      setAsyncOptions(response?.data.data);
      setterOptions(response?.data.data);
    } catch (error) {}
  };

  const primeraLlamada = async () => {
    try {
      const response: any = await service({
        params: { ...params, [nameSearch]: defaultValueText },
      });

      setAsyncOptions(response?.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const containsText = (text, searchText) => {
    return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  };

  const displayedOptions = useMemo(() => {
    async
      ? handleSearch()
      : options.filter((option) => containsText(option.label, searchText));
  }, [searchText, async]);

  useEffect(() => {
    setSearchText(defaultValueText);
    primeraLlamada();
  }, [defaultValueText]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, ref, value } }) => {
        return (
          <FormControl fullWidth>
            <InputLabel id="Label-name">{label}</InputLabel>

            <Select
              // Disables auto focus on MenuItems and allows TextField to be in focus
              MenuProps={{ autoFocus: false }}
              labelId="search-select-label"
              id="search-select"
              value={value}
              variant="filled"
              inputRef={ref}
              label={label}
              onChange={(e) => {
                onChange(e);
                setSelectedOption(e.target.value);
              }}
              onClose={() => (searchText.length === 0 ? setSearchText("") : "")}
              // This prevents rendering empty string in Select's value
              // if search text would exclude currently selected option.
              renderValue={(value) => {
                console.log(name, value);
                if (async) {
                  const val = asyncOptions?.find((option: any) => {
                    return option[itemValue] == value;
                  })?.[itemLabel];

                  return val;
                } else {
                  return options.filter(
                    (options) => options.value === selectedOption
                  )[0]?.[itemLabel];
                }
              }}
              readOnly={readOnly}
              disabled={disabled}
            >
              {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
              <ListSubheader>
                <TextField
                  size="small"
                  // Autofocus on textfield
                  autoFocus
                  placeholder="Escriba para buscar..."
                  fullWidth
                  defaultValue={
                    searchText
                      ? searchText === "undefined"
                        ? ""
                        : searchText
                      : ""
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <GridSearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {async
                ? asyncOptions?.map((option, i) => (
                    <MenuItem key={i} value={option[itemValue]}>
                      {`${option[itemLabel]} ${option[itemLabel2] ? "-" : ""} ${
                        option[itemLabel2] ?? ""
                      }`}
                    </MenuItem>
                  ))
                : (displayedOptions as any)?.map((option, i) => (
                    <MenuItem key={i} value={option[itemValue]}>
                      {`${option[itemLabel]} ${option[itemLabel2] ? "-" : ""} ${
                        option[itemLabel2] ?? ""
                      }`}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        );
      }}
    />
  );
};

export default SelectSearch;
