import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { memo, useState } from "react";
import { Controller } from "react-hook-form";
import tema from "utils/theme";
import { IFormFields } from "../../../interfaces/FormFields";

const myStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
  },
  error: {
    margin: "5px",
    color: tema.principal.error,
  },
  boton: {
    "& svg": {
      color: tema.principal.dark,
    },
  },
});

const PasswdField: React.FC<IFormFields> = ({
  name = "",
  control,
  rules = {},
  variant = "outlined",
  label = "",
  error = false,
  disabled = false,
  value = null,
  handleBlur = () => {
    return null;
  },
  errmsg = { message: "" },
}) => {
  const classes = myStyles();
  const [showPass, setShowPass] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPass((prevState) => !prevState);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <div className={classes.root}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormControl variant={variant} fullWidth>
            <InputLabel htmlFor={`${name}_adornment`}>{label}</InputLabel>
            <Input
              id={`${name}_adornment`}
              type={showPass ? "text" : "password"}
              fullWidth
              sx={{ minWidth: "100%" }}
              error={error && true}
              value={value}
              onChange={(e) => onChange(e)}
              onBlur={(e) => {
                handleBlur();
                onBlur();
              }}
              disabled={disabled}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    className={classes.boton}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        )}
      />
      {error && <p className={classes.error}>{errmsg.message}</p>}
    </div>
  );
};

export default memo(PasswdField);
