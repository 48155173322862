export { DISPLAY } from "./display";
export { iconList } from "./icons";
export { NATIONALITY } from "./nationality";
export { QUINCENA } from "./quincena";
export { RULES } from "./rules";
export {prefijo} from './telefono'

export const localToken = "x-me-token";
export const recoverToken = "x-me-recover";
export const SITE_KEY = "6LdoBOUoAAAAACoDPT3wIKKBQn5-vQdg7fCay9zh";

export const SNACKBARS_IN_DISPLAY: number = 5;
export const API_CUENTA = process.env.REACT_APP_API_URL_SISTAD;
export const API_SAIME = process.env.REACT_APP_API_URL_SAIME;
export const API_MPPE = process.env.REACT_APP_API_URL_MPPE;


export const SEXO = [
  {
    id: "M",
    name: "Masculino",
  },
  {
    id: "F",
    name: "Femenino",
  },
];

export const EstadoCivil = [
  {
    id: "S",
    name: "Soltero",
  },
  {
    id: "V",
    name: "Viudo",
  },
  {
    id: "C",
    name: "Casado",
  },
  {
    id: "D",
    name: "Divorciado",
  },
];

export const CodigoBanco = [
  {
    id: "0102",
    name: "Banco de Venezula",
  },
  {
    id: "0175",
    name: "Banco Bicentenario",
  },
];

export const NivelInstruccion = [
  {
    id: "P",
    name: "Primaria",
  },
  {
    id: "B",
    name: "Bachiller",
  },
  {
    id: "T",
    name: "Tsu",
  },
  {
    id: "L",
    name: "Licenciado",
  },
];


export const ESTATUS = {
  A: "Activo",
  F: "Fallecido",
  C: "Contratado",
  I: "Inactivo",
};