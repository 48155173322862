import { request } from "config/request";
import { API_CUENTA } from "constants/index";
import { iRequest } from "interfaces/api";

export const LoginService = (props: iRequest) => {
  const { params, authRequire, payload } = props;
  delete payload?.captcha;
  return request(API_CUENTA + "/auth/sign-in", {
    method: "post",
    data: payload,
    authRequire,
    params: params,
  });
};

export const VerifyService = (props: iRequest) => {
  const { authRequire, payload } = props as iRequest;

  return request(`${API_CUENTA}/cuenta/registro/01/comprobar/`, {
    method: "post",
    data: payload,
    authRequire,
  });
};
