import { Typography } from "@mui/material";
import SearchFormCedula from "components/presentationals/SearchFormCedula";

const ConsultaTrabajador = ({ onSubmit, disabled }) => {
  const defaultValues = {
    nacionalidad: "",
    ci: "",
  };

  return (
    <>
      <Typography
        align="center"
        variant="h5"
        sx={{ marginY: 2, fontWeight: "bold", color: "#333" }}
      >
        Consulta del Trabajador
      </Typography>
      <br />
      <SearchFormCedula
        nextColor="success"
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        disabled={disabled}
      />
    </>
  );
};

export default ConsultaTrabajador;
