import Construccion from "pages/construccion/Construccion";
import Dash from "pages/dashboard";
import Login from "pages/login/Login";
import { TMenuRoutes, TRoute } from "types";
import { typeRouteEnum } from "utils/enums";
import { NAVIGATION } from "./navigation";

export const routes: TRoute[] = [
  {
    label: "Principal",
    path: NAVIGATION.HOME,
    component: Dash,
    typeRoute: typeRouteEnum.PRIVATE,
  },
  {
    label: "Construccion",
    path: NAVIGATION.CONSTRUCTION,
    component: Construccion,
    typeRoute: typeRouteEnum.PUBLIC,
  },
  {
    label: "iniciar-sesion",
    path: NAVIGATION.LOGIN,
    component: Login,
    typeRoute: typeRouteEnum.PUBLIC,
  },
];

export const MenuRoutes: TMenuRoutes[] = [
  {
    id: 1,
    name: "Módulo de Ingreso",
    path: "/ingreso",
    icon: "default",
    display: "principal",
  },
];
