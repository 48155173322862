import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { makeStyles, styled } from "@mui/styles";
import dayjs from "dayjs";
import { ESTATUS } from "../../../../constants/index";
import { TIPO_PERSONA } from "constants/tipo_persona";

//styles
import styles from "./DetalleTrabajador.module.scss";

export const useStyles = makeStyles({
  root: {},
  listItem: {
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  primaryText: {
    color: "#2166A1",
    fontWeight: "bold!important",
    fontSize: "1.2em!important",
  },
  secondaryText: {
    color: "#555",
    fontSize: "1.1em!important",
  },
  reject: {
    fontSize: "1.1em!important",
    color: "red!important",
  },
});

const DetalleTrabajador = ({
  trabajador,
  handleNext,
  handleBack,
}: {
  trabajador: any;
  handleBack;
  handleNext: any;
}) => {
  const classes = useStyles();
  const { dataIngreso = null, dataNomina = null } = trabajador;

  const cedula = dataIngreso?.ci ?? dataNomina?.cedula;

  const nombres = dataIngreso?.nombre
    ? `${dataIngreso?.apellidos} ${dataIngreso?.nombre}`
    : dataNomina?.nombre_apellido;

  const status = dataNomina?.estatus
    ? ESTATUS[dataNomina.estatus.toUpperCase()]
    : dataIngreso?.estatus
    ? dataIngreso?.estatus
    : ESTATUS.I;

  const sexo = String(dataNomina?.sexo ?? dataIngreso?.sexo).toLowerCase();
  const fecha_nacimiento =
    dataNomina?.fecha_nacimiento ?? dataIngreso?.f_nacimiento;

  const tpersona = TIPO_PERSONA.find(
    ({ value }) => trabajador?.dataIngreso?.tpersona === value
  ) ?? { label: dataNomina?.clasificacion };

  const dependencia = dataIngreso?.dependencia
    ? `${dataIngreso?.dependencia?.ddependencia} (${dataIngreso?.dependencia.cdependencia})`
    : `${dataNomina?.dependencia ?? ""} (${
        dataNomina?.codigo_dependencia ?? ""
      })`;

  const cargo = dataIngreso?.cargo
    ? `${dataIngreso?.cargo?.dcargo} (${dataIngreso?.cargo?.ccargo})`
    : `${dataNomina?.cargo} (${dataNomina?.codigo_cargo})`;

  return (
    <>
      <Box className={styles["header"]}>
        <Typography variant="h6" color="#000" fontWeight="bold">
          Datos del trabajador {`CI. ${cedula}`}
        </Typography>
        <Stack direction="column" justifyContent="center" spacing={2}>
          <Tooltip arrow title="Regresar a consultar trabajador">
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              color="error"
              onClick={handleBack}
            >
              Cancelar
            </Button>
          </Tooltip>
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            color="success"
            onClick={handleNext}
          >
            {Boolean(dataIngreso) ? "Actualizar" : "Registrar"}
          </Button>
        </Stack>
      </Box>

      <List className={classes.root}>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary={nombres}
            secondary={status}
            classes={{
              primary: classes.primaryText,
              secondary:
                status === ESTATUS.F ? classes.reject : classes.secondaryText,
            }}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            sx={{ textTransform: "capitalize" }}
            primary="Género"
            secondary={sexo}
            classes={{
              primary: classes.primaryText,
              secondary: classes.secondaryText,
            }}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary="Fecha de nacimiento"
            secondary={dayjs(fecha_nacimiento).format("DD/MM/YYYY")}
            classes={{
              primary: classes.primaryText,
              secondary: classes.secondaryText,
            }}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary="Dependencia"
            secondary={dependencia}
            classes={{
              primary: classes.primaryText,
              secondary: classes.secondaryText,
            }}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary="Tipo de persona"
            secondary={
              tpersona ? tpersona.label : trabajador?.dataIngreso?.tpersona
            }
            classes={{
              primary: classes.primaryText,
              secondary: classes.secondaryText,
            }}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary="Cargo"
            secondary={cargo}
            classes={{
              primary: classes.primaryText,
              secondary: classes.secondaryText,
            }}
          />
        </ListItem>
      </List>
    </>
  );
};

export default DetalleTrabajador;
