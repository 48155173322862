export const prefijo = [

  { value: '0291' },
  { value: '0292' },
  { value: '0295' },
  { value: '0255' },
  { value: '0256' },
  { value: '0257' },
  { value: '0272' },
  { value: '0293' },
  { value: '0294' },
  { value: '0275' },
  { value: '0276' },
  { value: '0277' },
  { value: '0278' },
  { value: '0271' },
  { value: '0272' },
  { value: '0212' },
  { value: '0251' },
  { value: '0253' },
  { value: '0254' },
  { value: '0261' },
  { value: '0262' },
  { value: '0263' },
  { value: '0264' },
  { value: '0265' },
  { value: '0266' },
  { value: '0267' },
  { value: '0271' },
  { value: '0275' },
  { value: '0412' },
  { value: '0414' },
  { value: '0424' },
  { value: '0416' },
  { value: '0426' }
];
