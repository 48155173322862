import { request } from "config/request";
import { API_CUENTA } from "constants/index";
import { iRequest } from "interfaces/api";

export const PayrollService = (props: iRequest) => {
  const { params, authRequire = true } = props;
  return request(API_CUENTA + "/payroll/consult-worker", {
    method: "get",
    authRequire,
    params,
  });
};

export const getCharges = (props: iRequest) => {
  const {params,authRequire = true } = props;
  return request(API_CUENTA + "/chargers", {
    method: "get",
    authRequire,
    params
  });
};

export const getDependencies = (props: iRequest) => {
  const { params, authRequire = true } = props;
  return request(API_CUENTA + "/dependencies", {
    method: "get",
    authRequire,
    params
    
  });
};


export const getGrades = () => {

  return request(API_CUENTA + "/grados", {
    method: "get",
    authRequire: true,
  
  });
}

export const getCodUnico = (props: iRequest) => {
  const { params, authRequire = true } = props;
  return request(API_CUENTA + "/cod-unique", {
    method: "get",
     authRequire,
    params  
  });
}

export const getMaterias = (props: iRequest) => {
  const { params, authRequire = true } = props;
  return request(API_CUENTA + "/materias", {
    method: "get",
     authRequire,
    params  
  });
}