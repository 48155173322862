import { request } from "config/request";
import { iRequest } from "interfaces/api";

export const ComunityService = (props: iRequest) => {
  const { params } = props;
  return request(`https://api.mppe.gob.ve/api/comunidades`, {
    method: "get",
    params: params,
  });
};
