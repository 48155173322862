import { TextField } from "@mui/material";
import React, { memo, useState } from "react";
import { Controller } from "react-hook-form";
import { IFormFields } from "types/index";
import styles from "./Fields.module.scss";

const FormField: React.FC<IFormFields> = ({
  hide = false,
  name = "",
  control,
  rules = {},
  handleChange = () => null,
  handleBlur = (name) => null,
  variant = "outlined",
  label = "",
  error = false,
  multiline = false,
  readOnly = false,
  type = "",
  disabled = false,
  labelProps = undefined,
  autoFocus = false,
  errmsg = { message: "" },
  maxLength = null,
}) => {
  const [showPass] = useState<boolean>(true);

  return (
    <div className={hide ? styles["input_hide"] : styles["input_text"]}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value, onBlur } }) => (
          <TextField
            name={name}
            variant={variant}
            id={`${name}_id`}
            label={label}
            type={showPass ? type : "text"}
            value={value}
            error={error && true}
            onChange={(e) => {
              onChange(e);
              handleChange(e);
            }}
            onBlur={(e) => {
              onBlur();
              handleBlur(e);
            }}
            fullWidth
            multiline={multiline}
            rows={4}
            inputProps={{
              readOnly: readOnly,
              autoFocus,
              min: 0,
              maxLength: maxLength,
            }}
            InputLabelProps={labelProps}
            disabled={disabled}
          />
        )}
      />
      {error && <p className={styles["input_text_error"]}>{errmsg.message}</p>}
    </div>
  );
};

export default memo(FormField);
