import Drawer from "@mui/material/Drawer";
import ListMenu from "./ListMenu";

const drawerWidth = 240;

interface Props {
  open: boolean;
  handleOpen: () => void;
  window?: () => Window;
  movil: boolean;
}

export default function ResponsiveDrawer(props: Props) {
  const { window, open, handleOpen, movil } = props;

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Drawer
      container={container}
      variant="temporary"
      open={open}
      onClose={handleOpen}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
          background: "#2166A1",
        },
      }}
    >
      <ListMenu movil={movil} />
    </Drawer>
  );
}
