import { Stack, Typography } from "@mui/material";
import { localToken } from "constants/index";
import React, { memo, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import NAVIGATION from "routes/navigation";
import { searchItemLocal } from "utils/helpers";
import IPage from "../../interfaces/page";
import LoginFrom from "./components/LoginFrom";

const Login: React.FC<IPage> = () => {
  const token = searchItemLocal(localToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) navigate(NAVIGATION.HOME);
  }, [token, navigate]);

  return (
    <>
      <Helmet>
        <title>SISTAD - Iniciar Sesión</title>
      </Helmet>
      <Stack p={4} spacing={2}>
        <Typography className="page-title-login" component="h1">
          INICIAR SESIÓN
        </Typography>
        <LoginFrom />
      </Stack>
    </>
  );
};

export default memo(Login);
