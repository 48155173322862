const ZonaEducativa=[{value:'Centro de calidad educativa-Distrito Capital'},
{value:'Centro de calidad educativa-Amazonas'},{value:'Centro de calidad educativa-Anzoategui'},{value:'Centro de calidad educativa-Apure'},{value:'Centro de calidad educativa-Aragua'},{value:'Centro de calidad educativa-Barinas'}
,{value:'Centro de calidad educativa-Bolívar'},{value:'Centro de calidad educativa-Carabobo'},{value:'Centro de calidad educativa-Cojedes'},{value:'Centro de calidad educativa-Delta Amacuro'},{value:'Centro de calidad educativa-Falcon'},{value:'Centro de calidad educativa-Guarico'},{value:'Centro de calidad educativa-Lara'},{value:'Centro de calidad educativa-Merida'}, {value:'Centro de calidad educativa-Bolivariano de Miranda'},{value:'Centro de calidad educativa-Monagas'},{value:'Centro de calidad educativa-Nueva Esparta'},{value:'Centro de calidad educativa-Portuguesa'},{value:'Centro de calidad educativa-Sucre'},{value:'Centro de calidad educativa-Tachira'},{value:'Centro de calidad educativa-Trujillo'},{value:'Centro de calidad educativa-La Guaira'},{value:'Centro de calidad educativa-Yaracuy'},{value:'Centro de calidad educativa-Zulia'}]


const CodEntidad = [
    {value:'01'},
    {value:'02'},
    {value:'03'},
    {value:'04'},
    {value:'05'},
    {value:'06'},
    {value:'07'},
    {value:'08'},
    {value:'09'},
    {value:'10'},
    {value:'11'},
    {value:'12'},
    {value:'13'},
    {value:'14'},
    {value:'15'},
    {value:'16'},
    {value:'17'},
    {value:'18'},
    {value:'19'},
    {value:'20'},
    {value:'21'},
    {value:'22'},
    {value:'23'},
    {value:'24'},

]
export { CodEntidad,ZonaEducativa}