import { alertsMessages } from "reducers/appSlice";
import { RegisterUser, UpdateUser } from "services/user";

export type IFormRegister = {
  nacionalidad: string;
  ci: string;
  nombre: string;
  apellidos: string;
  estado_civil: string;
  f_nacimiento: string;
  sexo: string;
  n_instrucion: string | null;
  f_ingreso: string;
  cdependencia_id: string;
  ccargo_id: string;
  // cod_entidad: string;
  horas: string;
  // cod_banco: string;
  nro_cuenta: string;
  tlf: string;
  dir_habitacion: string;
  zona_educativa: string;
  funcionario_solicitante: string;
  tpersona: string;
  modalidad: string;
  grado_instruccion: string;
  id_parroquia: string | number;
  antecedentes: any[];
  select_search: string;
  grados_id: string,
  prefijo_telefono: string
  estatus: string
  materias_id: string
  cod_unico_id: string
  id_estado: string
  id_municipio: string
  comunidades_id: string
};

export const DEFAULT_VALUES: IFormRegister = {
  nacionalidad: "V",
  ci: "",
  nombre: "",
  apellidos: "",
  estado_civil: "",
  f_nacimiento: "",
  sexo: "",
  n_instrucion: null,
  f_ingreso: "",
  cdependencia_id: "",
  ccargo_id: "",
  // cod_entidad: "",
  horas: "",
  estatus: "",
  // cod_banco: "",
  nro_cuenta: "",
  tlf: "",
  dir_habitacion: "",
  zona_educativa: "",
  funcionario_solicitante: "",
  tpersona: "",
  modalidad: "",
  grado_instruccion: "",
  prefijo_telefono: "",
  id_parroquia: "",
  antecedentes: [],
  select_search: "",
  grados_id: "",
  cod_unico_id: "",
  materias_id: "",
  id_estado: "",
  id_municipio: "",
  comunidades_id: "",
};

export const handleRegister = async ({
  data,
  clearErrors,
  reset,
  dispatch,
  handleBack,
  updateId,
}) => {
  try {
    if (updateId !== null) {

      await UpdateUser({
        payload: { ...data, id: updateId },
        authRequire: true,
      });
    } else {
      await RegisterUser({ payload: data, authRequire: true });
    }

    await clearErrors();
    reset(DEFAULT_VALUES);
    dispatch(
      alertsMessages({
        message: `Usuario ${updateId == null ? "creado" : "actualizado"
          } exitosamente, usted será redireccionado...`,
        name: "success",
      })
    );
    setTimeout(() => {
      handleBack();
    }, 1500);
  } catch (error) {
    console.log(error);
    dispatch(
      alertsMessages({
        message: `Error al ${updateId == null ? "crear" : "actualizado"
          } el usuario, verifique en intente nuevamente`,
        name: "error",
      })
    );
  }
};

export const handleBlurCodeCargo = (setValue) => (e) => {
  setValue(e.target.name, e.target.value.toString().padStart(5, "0"));
};
export const handleBlurCodeEntidad = (setValue) => (e) => {
  setValue(e.target.name, e.target.value.toString().padStart(5, "0"));
};
export const handleBlurCodeDependencia = (setValue) => (e) => {
  setValue(e.target.name, e.target.value.toString().padStart(5, "0"));
};

export const onClear: (reset) => void = (reset) => {
  reset(DEFAULT_VALUES);
};
