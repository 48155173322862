import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const myStyles = makeStyles({
  root: {
    backgroundColor: "#005b9a",
  },
  images: {
    margin: "0px",

    "& > img": {
      width: "100%",
      maxHeight: "70px",
    },
  },
});

const LoginHeader: React.FC = () => {
  const classes = myStyles();
  return (
    <header>
      <Stack
        className={classes.root}
        direction={{ xs: "column", sm: "row" }}
        justifyContent="center"
        alignItems="center"
      >
        <figure className={classes.images}></figure>
      </Stack>
    </header>
  );
};

export default LoginHeader;
