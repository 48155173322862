import SendIcon from "@mui/icons-material/Send";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useStyles } from "../ConsultaTrabajador/DetalleTrabajador";

const DatosSaime = ({ trabajador, handleNext }: any) => {
  const classes = useStyles();
  return (
    <>
    <br /><br />
      <Stack direction="row" spacing={2}>
        <Typography variant="h6" color="#000" fontWeight="bold">
          Datos del trabajador{" "}
          {trabajador?.cedula ? `CI. ${trabajador?.cedula}` : "-"}
        </Typography>
      </Stack>
      <List className={classes.root}>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary={`${trabajador?.primer_nombre} ${trabajador?.segundo_nombre ?? ''} ${trabajador?.primer_apellido} ${trabajador?.segundo_apellido}`}
            secondary={`Género: ${
              trabajador?.sexo === "M" ? "Masculino" : "Femenino"
            }`}
            classes={{
              primary: classes.primaryText,
              secondary: classes.secondaryText,
            }}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary="Fecha de nacimiento"
            secondary={
              trabajador?.fecha_nacimiento
                ? dayjs(trabajador?.fecha_nacimiento).format("DD/MM/YYYY")
                : "-"
            }
            classes={{
              primary: classes.primaryText,
              secondary: classes.secondaryText,
            }}
          />
        </ListItem>
      </List>
      <Stack direction="column" alignItems="center" spacing={2}>
        <Typography>
          Para continuar con el ingreso del trabajador, por favor continúe al
          formulario...
        </Typography>
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          color="success"
          onClick={handleNext}
        >
          Realizar Ingreso
        </Button>
      </Stack>
    </>
  );
};

export default DatosSaime;
