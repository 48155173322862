import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { memo, useState } from "react";
import { Controller } from "react-hook-form";
import { ISelectFields } from "types";
import styles from "./Fields.module.scss";

const SelectForm: React.FC<ISelectFields> = ({
  name = "",
  nextData = () => {
    return null;
  },
  control,
  variant = "standard",
  label = "label",
  options = [],
  itemId = "id",
  itemLabel = "name",
  error = false,
  itemLabel2 = "",
  rules = {},
  disabled = false,
  defaultData = {},
  defaultValue = "",
  errmsg = { message: "" },
  handleChange = () => {
    return null;
  },
  readOnly = false,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const loadMoreItems = (e) => {
    const bottom =
      e.target.scrollHeight ==
      parseInt(e.target.scrollTop + e.target.clientHeight);

    if (bottom) {
      // addc your code here
      nextData(e);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <FormControl fullWidth>
              <InputLabel id="Label-name">{label}</InputLabel>

              <Select
                labelId={`${label}-id`}
                label={label}
                id={`${name}_id`}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                onChange={(e) => {
                  onChange(e);
                  handleChange(e, name);
                }}
                defaultValue={defaultValue}
                MenuProps={{ PaperProps: { onScroll: loadMoreItems } }}
                name={name}
                value={value}
                variant={variant}
                inputProps={{ readOnly }}
                style={{ width: "100%" }}
                error={error ? true : false}
                fullWidth
                inputRef={ref}
                disabled={disabled}
              >
                {Object.keys(defaultData).length > 0 && (
                  <MenuItem value={defaultData[itemId] as string}>
                    {`${defaultData[itemLabel]} ${
                      defaultData[itemLabel2] ? "-" : ""
                    }  ${defaultData[itemLabel2] ?? ""}`}
                  </MenuItem>
                )}

                {options.map((e: Record<string, unknown>, index: number) => {
                  return (
                    <MenuItem value={e[itemId] as string} key={index}>
                      {`${e[itemLabel]} ${e[itemLabel2] ? "-" : ""}  ${
                        e[itemLabel2] ?? ""
                      }`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          );
        }}
      />
      {error && <p className={styles["input_text_error"]}>{errmsg.message}</p>}
    </>
  );
};

export type TSelectOptions = {
  id: string;
  name: string;
};
export default memo(SelectForm);
