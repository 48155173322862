// Vendors
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { localToken } from "constants/index";
/* Interfaces */
import { Auth, IAction, iPayloadString } from "interfaces/index";
import jwt_decode from "jwt-decode";
/* Services */
import { LoginService } from "services/auth";
/* helpers */

import { removeItemLocal, searchItemLocal, setItemLocal } from "utils/helpers";
import { alertsMessages } from "./appSlice";


const PREFIX = "AUTH";
const authAdapter = createEntityAdapter<Auth>({});
const initialState: Auth = {
  isAuthenticated: false,
  isLoading: false,
  success: false,
  isVerified: false,
  user: {
    id: "",
    verificacion: "",
    username: "",
    email: "",
    origen: "",
    cedula: "",
  },
};



/**
 * Iniciar sesión
 * @param username :string, 
 * @param password :string, 
  @return mixed
 */
export const login = createAsyncThunk(
  `${PREFIX}/INICIAR-SESION`,
  async (payload: iPayloadString, { dispatch }) => {
    try {
      const response = await LoginService({ payload });
      const token = response.data.accessToken;
      setItemLocal(localToken, token);
    } catch (error: any) {
      dispatch(
        alertsMessages({
          message: error?.response?.data?.message,
          name: "error",
        })
      );
      throw Error();
    }
  }
);

/**
 * Verificar token al refrescar *
  @return mixed
 */
export const reloadingBrowser = createAsyncThunk(
  `${PREFIX}/RELOADING-BROWSER`,
  () => {
    const token = searchItemLocal(localToken);

    if (!token) {
      removeItemLocal(localToken);
    }

    const decoded: any = jwt_decode(token);

    if (decoded.exp > Math.floor(Date.now() / 1000)) {
      const { user_id, email, verificacion, origen, cedula } = decoded;

      return {
        user: { id: user_id, email, verificacion, origen, cedula },
      };
    } else removeItemLocal(localToken);
  }
);

/**
 * Cerrar sesion *
  @return mixed
 */
export const logout = createAsyncThunk(`${PREFIX}/logout`, () => {
  removeItemLocal(localToken)

}
);

export const authSlice = createSlice({
  name: PREFIX,
  initialState: authAdapter.getInitialState(initialState),
  reducers: {
    clearVerified(state) {
      state.isVerified = false;
    },
    authenticated(state) {
      state.isAuthenticated = true;
    },
    expiredAuth(state) {
      state.isAuthenticated = false;
    },
  },
  extraReducers: (build) => {
    /* Caso LoginWorker */
    build.addCase(login.pending, (state) => {
      state.isLoading = true;
    });

    build.addCase(login.fulfilled, (state, { payload }: IAction) => {
      state.isLoading = initialState.isLoading;
      state.isAuthenticated = true;
    });
    build.addCase(login.rejected, (state) => {
      state.isLoading = initialState.isLoading;
    });

    /* caso Logout */
    build.addCase(logout.fulfilled, (state) => {
      state.isLoading = false;
      state.isAuthenticated = initialState.isAuthenticated;
      state.user = initialState.user;
    });
    build.addCase(logout.pending, (state) => {
      state.isLoading = true;
    });

    /* Caso ReloadBrowser */
    build.addCase(reloadingBrowser.fulfilled, (state, { payload }: IAction) => {
      state.isAuthenticated = true;
      state.user = payload.user;
    });
    build.addCase(reloadingBrowser.rejected, (state) => {
      state.isAuthenticated = false;
      state.user = initialState.user;
    });
  },
});

//Actions
export const { clearVerified } = authSlice.actions;
// Reducer
export default authSlice.reducer;
