import { Container, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FunctionComponent, PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import tema from "utils/theme";
import FooterLogin from "../Login/FooterLogin";
import LoginHeader from "../Login/LoginHeader";

const myStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    minHeight: "100vh",
  },
  main: {
    background: tema.principal.dark,
    height: "100%",
    display: "flex",
    padding: "2.5rem 0",
  },
  container: {
    margin: "auto",
  },
  figure: {
    height: "100%",
    minHeight: "350px",
    width: "100%",

    "& img": {
      width: "100%",
      objectFit: "cover",
      filter: "brightness(0.8)",
      borderRadius: "14px 0 0 14px",
    },
  },
});

const PublicLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const classes = myStyles();

  return (
    <section className={classes.root}>
      <LoginHeader />
      <main>
        <div className={classes.main}>
          <Container className={classes.container} maxWidth="sm">
            <Paper elevation={2} sx={{ borderRadius: 4 }}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  {children ? children : <Outlet />}
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </div>
      </main>
      <FooterLogin />
    </section>
  );
};

export default PublicLayout;
