import MuiDrawer from "@mui/material/Drawer";
import { CSSObject, Theme, styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React from "react";
import logoClose from "static/img/Recorte_logo_close_transparent.png";
import logo from "static/img/Logo_transparent_2.0-removebg-preview.png";
import tema from "utils/theme";
import ListMenu from "./ListMenu";

const drawerWidth = 240;

const myStyles: any = makeStyles({
  header: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: tema.spacing(0, 1),
  },
});

const openedMixin = (theme: Theme): CSSObject => ({
  background: tema.principal.dark,
  border: "none",
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: "hidden",
  position: "static",
});

const closedMixin = (theme: Theme): CSSObject => ({
  background: tema.principal.dark,
  border: "none",
  whiteSpace: "nowrap",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  position: "static",
  width: `calc(${theme.spacing(7)} + 0px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 0px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "break-spaces",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MenuDrawer: React.FC<menuDrawerProps> = ({ open, sx, movil }) => {
  const classes = myStyles();

  return (
    <Drawer sx={sx} variant="permanent" open={open}>
      <div className={classes.header}>
        {open ? (
          <img
            src={logo}
            alt="logo"
            style={{ width: "180px", height: "100px", objectFit: "contain" }}
          />
        ) : (
          <img
            src={logoClose}
            alt="logo"
            style={{ width: "60px", height: "100px", objectFit: "contain" }}
          />
        )}
      </div>

      <ListMenu movil={movil} />
    </Drawer>
  );
};
export type menuDrawerProps = { open: boolean; sx: object; movil: boolean };
export default MenuDrawer;
