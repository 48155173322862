
export const horasOpt = [
    { tpersona: 'A', hr: '0.00' },
    { tpersona: 'A', hr: '37.50' },
    { tpersona: 'D', hr: '0.00' },
    { tpersona: 'D', hr: '1.00' },
    { tpersona: 'D', hr: '2.00' },
    { tpersona: 'D', hr: '3.00' },
    { tpersona: 'D', hr: '4.00' },
    { tpersona: 'D', hr: '5.00' },
    { tpersona: 'D', hr: '6.00' },
    { tpersona: 'D', hr: '7.00' },
    { tpersona: 'D', hr: '8.00' },
    { tpersona: 'D', hr: '9.00' },
    { tpersona: 'D', hr: '10.00' },
    { tpersona: 'D', hr: '11.00' },
    { tpersona: 'D', hr: '12.00' },
    { tpersona: 'D', hr: '13.00' },
    { tpersona: 'D', hr: '13.30' },
    { tpersona: 'D', hr: '13.33' },
    { tpersona: 'D', hr: '14.00' },
    { tpersona: 'D', hr: '15.00' },
    { tpersona: 'D', hr: '15.33' },
    { tpersona: 'D', hr: '16.00' },
    { tpersona: 'D', hr: '17.00' },
    { tpersona: 'D', hr: '18.00' },
    { tpersona: 'D', hr: '19.00' },
    { tpersona: 'D', hr: '20.00' },
    { tpersona: 'D', hr: '20.33' },
    { tpersona: 'D', hr: '21.00' },
    { tpersona: 'D', hr: '21.33' },
    { tpersona: 'D', hr: '22.00' },
    { tpersona: 'D', hr: '23.00' },
    { tpersona: 'D', hr: '23.33' },
    { tpersona: 'D', hr: '24.00' },
    { tpersona: 'D', hr: '25.00' },
    { tpersona: 'D', hr: '26.00' },
    { tpersona: 'D', hr: '27.00' },
    { tpersona: 'D', hr: '28.00' },
    { tpersona: 'D', hr: '29.00' },
    { tpersona: 'D', hr: '30.00' },
    { tpersona: 'D', hr: '31.00' },
    { tpersona: 'D', hr: '32.00' },
    { tpersona: 'D', hr: '33.00' },
    { tpersona: 'D', hr: '33.30' },
    { tpersona: 'D', hr: '33.33' },
    { tpersona: 'D', hr: '34.00' },
    { tpersona: 'D', hr: '35.00' },
    { tpersona: 'D', hr: '36.00' },
    { tpersona: 'D', hr: '37.00' },
    { tpersona: 'D', hr: '37.33' },
    { tpersona: 'D', hr: '37.50' },
    { tpersona: 'D', hr: '38.00' },
    { tpersona: 'D', hr: '39.00' },
    { tpersona: 'D', hr: '40.00' },
    { tpersona: 'D', hr: '40.01' },
    { tpersona: 'D', hr: '41.00' },
    { tpersona: 'D', hr: '41.33' },
    { tpersona: 'D', hr: '42.00' },
    { tpersona: 'D', hr: '43.00' },
    { tpersona: 'D', hr: '43.33' },
    { tpersona: 'D', hr: '44.00' },
    { tpersona: 'D', hr: '45.00' },
    { tpersona: 'D', hr: '45.33' },
    { tpersona: 'D', hr: '46.00' },
    { tpersona: 'D', hr: '47.00' },
    { tpersona: 'D', hr: '48.00' },
    { tpersona: 'D', hr: '48.48' },
    { tpersona: 'D', hr: '49.00' },
    { tpersona: 'D', hr: '50.00' },
    { tpersona: 'D', hr: '51.00' },
    { tpersona: 'D', hr: '51.33' },
    { tpersona: 'D', hr: '52.00' },
    { tpersona: 'D', hr: '53.00' },
    { tpersona: 'D', hr: '53.23' },
    { tpersona: 'D', hr: '53.30' },
    { tpersona: 'D', hr: '53.33' },
    { tpersona: 'D', hr: '53.53' },
    { tpersona: 'D', hr: '53.55' },
    { tpersona: 'D', hr: '54.00' },
    { tpersona: 'D', hr: '55.00' },
    { tpersona: 'O', hr: '0.00' },
    { tpersona: 'O', hr: '37.00' },
    { tpersona: 'O', hr: '37.50' }
]

