export const MODALIDAD = [
    {
        value: '1',
        label: 'Mañana'
    },
    {
        value: '2',
        label: 'Tarde'
    },
    {
        value: '7',
        label: 'Mañana-Tarde'
    },
    {
        value: '3',
        label: 'Nocturno'
    },
    {
        value: '4',
        label: 'Completo'
    },
    {
        value: '5',
        label: 'Integral-Mixto'
    },
    {
        value: '6',
        label: 'Sabatino'
    },

]