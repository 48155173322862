import { request } from "config/request";
import { iRequest } from "interfaces/api";

export const MunicipalitiesService = (props: iRequest) => {
  const { params } = props;
 
  return request(`https://api.mppe.gob.ve/api/municipios`, {
    method: "get",
    params: params,
  });
};
