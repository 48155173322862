import { Box, Container, Typography } from "@mui/material";
import Breadcrumb from "components/presentationals/BreadCumb";
import FacebookCircularProgress from "components/presentationals/CircularProgress/CircularProgress";
import { IPROPS } from "interfaces";
import IPage from "interfaces/page";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { SubmitHandler } from "react-hook-form";
import { PayrollService } from "services/payroll";
import { SaimeService } from "services/user";
import ConsultaTrabajador from "./components/ConsultaTrabajador";
import DetalleTrabajador from "./components/ConsultaTrabajador/DetalleTrabajador";
import DatosSaime from "./components/DatosSaime";
import DatosTrabajador from "./components/DatosTrabajador";

const Dashboard: React.FC<IPage> = () => {
  const [worker, setWorker] = useState<any | null>(null);
  const [loadWorker, setLoadWorker] = useState<boolean | null>(null);
  const [loadForm, setLoadForm] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrors] = useState<string | null>(null);
  const [isSaime, setIsSaime] = useState<any>(false);
  const showWorker = worker && loadWorker;
  const showSaime = isSaime && !loading;

  const getSaime = async (payload) => {
    try {
      const { data } = await SaimeService({ payload });
      setIsSaime(data);
    } catch (error) {
      setLoadWorker(false);
      setErrors("No se encontró datos del trabajador indicado...");
    }
  };

  const getData = async (params) => {
    setLoading(true);
    try {
      const { data } = await PayrollService({ params });
      return data;
    } catch (error: any) {
      await getSaime(params);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setWorker(false);
    setIsSaime(false);
    setLoadForm(null);
  };

  const onSubmit: SubmitHandler<IPROPS> = async (payload) => {
    setErrors(null);
    setWorker(null);

    const dataWorker = await getData(payload);
    if (dataWorker) {
      setWorker(dataWorker);
      setLoadWorker(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>ME - Módulo de Ingreso</title>
      </Helmet>
      <Container sx={{ border: 0 }}>
        <Breadcrumb
          breadcrumbs={[{ path: "/ingreso", label: "Módulo de Ingreso" }]}
        />
        {!showWorker && loadForm === null && (
          <ConsultaTrabajador onSubmit={onSubmit} disabled={loading} />
        )}

        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 6,
            }}
          >
            <FacebookCircularProgress />
          </Box>
        )}

        {showSaime && !worker && (
          <>
            <DatosSaime
              trabajador={isSaime}
              handleNext={() => {
                setLoadForm(true);
                setLoadWorker(null);
                setWorker({
                  nombre_apellido: `${isSaime?.primer_nombre} ${isSaime?.primer_apellido}`,
                  origen: isSaime.origen,
                  cedula: isSaime.cedula,
                  fecha_nacimiento: isSaime.fecha_nacimiento,
                  sexo: isSaime.sexo,
                });
              }}
            />
          </>
        )}
        {showWorker && !loading && (
          <DetalleTrabajador
            trabajador={worker}
            handleBack={() => {
              setLoadForm(null);
              setLoadWorker(null);
            }}
            handleNext={() => {
              setLoadForm(true);
              setLoadWorker(null);
            }}
          />
        )}
        {loadForm && !showWorker && (
          <DatosTrabajador handleBack={handleBack} initialValues={worker} />
        )}
        {!loading && errorMessage && (
          <>
            <Typography
              sx={{ marginTop: 6 }}
              color="info"
              align="center"
              variant="h6"
              fontWeight="bold"
            >
              {errorMessage}
            </Typography>
          </>
        )}
      </Container>
    </>
  );
};

export default Dashboard;
